import { useEffect, useRef } from 'react';

const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    const tick = () => savedCallback.current();
    const interval = setInterval(tick, delay);

    return () => clearInterval(interval);
  }, [delay]);
}

export { useInterval };
