// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/razzle/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".b7b6a725255f40d31e00c35f7c2edccd-scss{display:flex;position:relative;width:100%;height:100%;overflow:hidden}.b7b6a725255f40d31e00c35f7c2edccd-scss .c8108a4c6a69e6179a5dbd00486dbed9-scss{margin:0;padding:0;position:relative;width:100%;height:100%;display:flex;justify-content:center;align-items:flex-end}.b7b6a725255f40d31e00c35f7c2edccd-scss .c8108a4c6a69e6179a5dbd00486dbed9-scss li .ba27f344933c9e87402e02229b7b8c13-scss{position:absolute;visibility:hidden}.b7b6a725255f40d31e00c35f7c2edccd-scss .c8108a4c6a69e6179a5dbd00486dbed9-scss li .ba27f344933c9e87402e02229b7b8c13-scss:checked ~ ._91654ab41a9422ae90bd8f20f60fb8f0-scss{transition:transform 0.5s;transform:rotateY(0deg);transform-origin:right}.b7b6a725255f40d31e00c35f7c2edccd-scss .c8108a4c6a69e6179a5dbd00486dbed9-scss li ._91654ab41a9422ae90bd8f20f60fb8f0-scss{position:absolute;height:100%;width:100%;top:0;left:0;transition:transform 0.5s;transform:rotateY(90deg);transform-origin:left}.b7b6a725255f40d31e00c35f7c2edccd-scss .c8108a4c6a69e6179a5dbd00486dbed9-scss ._53a12c69a880488c1ce5cd2c8e56fe5e-scss{position:absolute;bottom:20px;display:flex;width:auto}.b7b6a725255f40d31e00c35f7c2edccd-scss .c8108a4c6a69e6179a5dbd00486dbed9-scss ._53a12c69a880488c1ce5cd2c8e56fe5e-scss ._5c7576f1e836107ab36349d46cc08fc2-scss{flex:1;height:10px;cursor:pointer;border:2px solid var(--white-light);margin:6px;transition:0.6s;z-index:1;min-width:20px}.b7b6a725255f40d31e00c35f7c2edccd-scss .c8108a4c6a69e6179a5dbd00486dbed9-scss ._53a12c69a880488c1ce5cd2c8e56fe5e-scss ._5c7576f1e836107ab36349d46cc08fc2-scss:hover{background:var(--white-light)}.b7b6a725255f40d31e00c35f7c2edccd-scss .c8108a4c6a69e6179a5dbd00486dbed9-scss ._53a12c69a880488c1ce5cd2c8e56fe5e-scss ._81d5435c69e6a6d0d6970d6e2f5d8a5e-scss{background:var(--white-light)}\n", ""]);
// Exports
exports.locals = {
	"container": "b7b6a725255f40d31e00c35f7c2edccd-scss",
	"ul": "c8108a4c6a69e6179a5dbd00486dbed9-scss",
	"radio": "ba27f344933c9e87402e02229b7b8c13-scss",
	"slide": "_91654ab41a9422ae90bd8f20f60fb8f0-scss",
	"navigation": "_53a12c69a880488c1ce5cd2c8e56fe5e-scss",
	"button": "_5c7576f1e836107ab36349d46cc08fc2-scss",
	"activeButton": "_81d5435c69e6a6d0d6970d6e2f5d8a5e-scss"
};
module.exports = exports;
