import React, { useState } from 'react';

import { useInterval } from 'hooks/interval';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';

export default ({ slides, children, customClasses }) => {
  useStyles(classes);
  const setRadios = () => {
    const radios = slides.map(() => false);
    radios[0] = true;
    return radios;
  }
  const [radiosChecked, setRadiosChecked] = useState(() => setRadios());

  const handleRadioChecked = index => {
    const newRadios = radiosChecked.map(() => false);
    newRadios[index] = true;
    setRadiosChecked(newRadios);
  };

  useInterval(() => {
    const index = radiosChecked.indexOf(true);
    handleRadioChecked(index === radiosChecked.length - 1 ? 0 : index + 1);
  }, 3000);

  return (
    <div className={customClasses ? `${classes.container} ${customClasses.container}` : classes.container}>
      <ul className={classes.ul}>
        {
          slides.map((slide, index) => 
            <li key={index}>
              <input 
                type='radio'
                name='slide'
                className={classes.radio} 
                onChange={() => handleRadioChecked(index)}
                checked={radiosChecked[index]}
              />
              <div className={classes.slide}>
                { children[index] }
              </div>
            </li>
          )
        }
        <div className={customClasses ? `${classes.navigation} ${customClasses.navigation}` : classes.navigation}
        >
          {
            slides.map((slide, index) => 
              <span 
                key={index}
                onClick={() => handleRadioChecked(index)}
                className={
                  radiosChecked[index] 
                    ? customClasses ? `${classes.button} ${classes.activeButton} ${customClasses.button}` : `${classes.button} ${classes.activeButton}`
                    : customClasses ? `${classes.button} ${customClasses.button}` : classes.button
                }
              />
            )
          }
        </div>
      </ul>
    </div>
  )
}